import { Component } from "react";

export default class CoT extends Component {
  componentDidMount() {
    window.location.replace(
      `${process.env.GATSBY_ACCOUNT_URL}/certificate/essential-for-women-multivitamin`,
    );
  }

  render() {
    return null;
  }
}
